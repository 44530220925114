<template>
    <div class="manage">
        <div class="header">
            <a-icon class="back" type="left" @click="$router.push({name:'user'})" />
            <span class="title"> 合约管理 </span>
        </div>
        <div class="content">
            <div class="box">
                <div class="title">菩提合约</div>
                <div class="subtitle">BOSS钱包地址</div>
                <div class="item">{{ buddhiContract.bossAddress }}</div>
                <div class="subtitle">USDT余额</div>
                <div class="flex item">
                    <span>{{ buddhiContract.usdtBalance }}USDT</span>
                </div>
                <div class="amount">
                    <a-input type="number" size="large" v-model="withdrawAmount" />
                </div>
                <div class="btn btn-primary-ghost withdraw-btn" @click="withdraw">提取</div>
            </div>
        </div>
    </div>
</template>
<script>
import Shelldao from "../web3/tron/shelldao";

export default {
    name: "Manage",
    data() {
        return {
            walletAddress: '',
            withdrawAmount: 100,
            buddhiContract: {
                bossAddress: '',
                usdtBalance: 0
            }
        };
    },
    components: {},
    mounted() {
        let _this = this;
        Shelldao.init((r)=>{
            console.log(r.gWalletAddress);
            this.walletAddress = r.gWalletAddress;
            r.gBudhiContract.boss().call().then((res)=>{
                console.log(window.tronWeb.address.fromHex(res));
                _this.buddhiContract.bossAddress = window.tronWeb.address.fromHex(res);
            });
            r.gUsdtToken.balanceOf(r.gBudhiContract.address).call().then((res)=>{
                console.log('usdt'+res.toNumber()/1000000);
                _this.buddhiContract.usdtBalance = res.toNumber() / 1000000;
            });
        });
        
    },
    methods: {
        withdraw(){
            if(this.walletAddress != this.buddhiContract.bossAddress){
                console.log(this.walletAddress, this.buddhiContract.bossAddress)
                this.$message.error('权限不足');
                return;
            }
            let _this =this;
            Shelldao.budhiWithdraw(this.withdrawAmount*1000000, ()=>{
                _this.$message.success('success');
                setTimeout(()=>{
                    _this.$forceUpdate;
                },1500)
            });
        }
    },
};
</script>
<style lang="less" scoped>
.manage {
    width:100%;
    padding: 30px 50px;
    .header {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        color: #fff;
        font-weight: bold;
        .back {
            position: absolute;
            left: 0;
        }
        .title {
            flex: 1;
        }
    }
    .content {
        margin: 50px auto;
        .box {
            text-align: left;
            padding: 150px 0;
            position: relative;
            overflow: hidden;
            
            .title {
                background: @primary-color;
                color: @white;
                position: absolute;
                font-weight: bold;
                top: 0;
                left: 0;
                width: 100%;
                line-height: 120px;
                text-align: center;
            }
            .subtitle {
                // color: @primary-color;
                // font-weight: bold;
                // border-left: 18px solid @primary-color;
                // padding-left: 24px;
                // opacity: .7;
                padding: 7px 24px;
                display: inline-block;
                background: #d8d8d8;
                color: #b37e25;
                font-weight: bold;
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
            }
            .item {
                padding: 20px;
                margin-bottom: 30px;
                .dis-btn {
                    padding: 10px 20px;
                    border-radius: 10px;
                    background: #d8d8d8;
                }
            }
            .amount {
                padding: 50px;
            }
            .withdraw-btn {
                margin: 10px 50px;
                padding: 28px;
            }
        }
    }
}
</style>
